//  import { Form, Button } from "react-bootstrap";
// import {Form, Modal, Button, Image} from "react-bootstrap";
// import {useDispatch} from "react-redux";
import React from "react";
//  import axios from "axios";
// import {BASE_URL} from "../BaseUrl";
// import {createServiceCustomization, fetchServicesCustomization} from "../reducers/ServiceCustomizationSlice";
// import {useFormik} from "formik";
// import {createCityZip, updateCityZip} from "../reducers/CityZipSlice";
// import {zipSchema} from "../schemas/zip";
// import {workerStatusUpdate} from "../reducers/TeamMemberSlice";


const OrderViewModal = ({close, data}) => {
 

    console.log('data: ',data)

    return (
        <>
            <div id="orderView" className="custom-modal">
                <div className="modal-dialog"></div>
                <div className="modal-content">
                    <div className="modal-header"><div className="text-lg">{data.serviceTitle}</div>
                        <span className="close-modal" onClick={close}>&times;</span>
                    </div>
                    <div className="modal-body">
                       <div className="short-description">
                            <div className="service-plan-title">{data.shortDescription}</div> 
                            {/* <p>${data.serviceAmount}</p>
                            <p>{data.servicePlanDescription}</p> */}
                        </div>  

                        <div className="box service-plan">
                            <div className="box-title"> {data.servicePlanName} : ${data.serviceAmount} </div>
                            <div className="box-description"> {data.servicePlanDescription}</div>
                        </div>
                        <div className="box customized-option">
                            <div className="box-title">Customized options </div>
                            <div className="attribute-list box-description">

                                {data.serviceJson && data.serviceJson.map((item, index) => (
                                    <div className="item"><img src='/images/square-ring.png' alt={item.title} />
                                        <strong>{item.title} : </strong>
                                        <span>{item.attributeValue}</span>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                        

                    </div>
                    <div className="modal-footer">
                        <span className="btn-close-t" onClick={close}> Close</span>
                    </div>
                </div>
            </div>
        </>
    );
};


export default OrderViewModal;

